.campaign-teaser {
  @apply
    shadow-lg
    rounded-lg
    p-4
    bg-white
    mb-4
    basic-transition
    transform;

  > a {
    @apply
      inline-block
      w-full
      h-full;
  }

  h4 {
    @apply
      text-xl
      font-bold
      basic-transition
      mb-1
      text-primary-blue;

    > svg {
      @apply
        inline-block
        text-primary-orange
        align-baseline;
    }
  }

  time {
    @apply
      text-sm
      opacity-50;
  }

  &:hover {
    @apply
      -translate-y-1
      shadow-xl;

    h4 {
      @apply
        text-primary-blue;
    }
  }
}