::v-deep(.vtl-table) {
  @apply
    mb-10;
}

::v-deep(.vtl-table .vtl-thead .vtl-thead-th) {
  @apply
    text-white
    bg-primary-blue
    border-primary-blue;
}

::v-deep(.vtl-table td),
::v-deep(.vtl-table tr) {
  @apply
    border-none;

  span {
    @apply
      text-xs;
  }
}
::v-deep(.vtl-table td) {
  @apply
    p-1;
}
::v-deep(.vtl-table th) {
  @apply
    p-1.5;
}
::v-deep(.vtl-thead-column) {
  @apply
    text-xs;
}
::v-deep(.vtl-paging-info) {
  @apply
    text-primary-lblue;

  > * {
    @apply
      text-sm;
  }
}
::v-deep(.vtl-paging-count-label),
::v-deep(.vtl-paging-page-label) {
  @apply
    text-primary-lblue
    text-sm;
}
::v-deep(.vtl-paging-pagination-page-link) {
  @apply
    border-none;
}
::v-deep(.vtl-tbody-tr:nth-of-type(even)) {
  @apply
    bg-primary-lgray;
}
::v-deep(.vtl-paging-count-dropdown),
::v-deep(.vtl-paging-page-dropdown) {
  @apply
    p-1
    ml-1
    h-7
    rounded-md
    text-sm;
}
::v-deep(.vtl-paging-page-label) {
  @apply
    ml-1;
}
::v-deep(.vtl-paging-pagination-ul li) {
  @apply
    text-sm;

  > * {
    @apply
      text-sm
      leading-normal;
  }

  a {
    @apply
      text-primary-orange;

    &:hover {
      @apply
        text-primary-dorange;
    }
  }
}