.page-view.home {
  @apply
    relative
    pb-10
    pt-10
    overflow-hidden;

  .subtitle {
    @apply
      text-primary-blue;

    .big {
      @apply
        font-bold
        text-2xl;
    }
  }

  h6.error {
    @apply
      mt-2
      text-red-500;
  }

  .loading {
    @apply
      mt-20
      uppercase
      tracking-wider
      font-bold
      text-primary-lblue;
  }

  .categories {
    @apply
      flex
      flex-wrap
      my-4;

    li {
      button {
        @apply
          mr-2
          mb-2
          text-xs
          bg-primary-lgray;
      }
    }

    &.filtered {
      li {
        button {
          @apply
            bg-white;
        }

        &.active {
          button {
            @apply
              bg-primary-gray;
          }
        }
      }
    }
  }

  .flex-wrapper {
    @apply
      w-full;

    .flex-item {
      @apply
        mb-2;

      @screen sm {
        @apply
          w-1/3
          mr-2;
      }

      @screen lg {
        @apply
          w-1/5
          mr-2;
      }

      a {
        @apply
          inline-block
          w-full
          h-full
          rounded-lg
          bg-primary-blue
          text-white
          basic-transition
          transform
          overflow-hidden;

        h6 {
          @apply
            px-4
            pt-2
            pb-1;
        }

        img {
          @apply
            object-cover
            h-48
            w-full;
        }

        span {
          @apply
            text-right
            block
            text-lg
            leading-normal
            -mb-1.5
            pr-2;
        }

        &:hover {
          @apply
            bg-primary-lblue
            shadow-md
            -translate-y-0.5;
        }
      }
    }

    .item {
      @screen lg {
        @apply
          w-1/3;

        &.large {
          @apply
            w-2/3
            pl-20;
        }
      }

      h5 {
        @apply
          mt-10
          text-xl
          text-primary-blue;
      }
    }
  }

  .form-wrapper {
    @apply
      max-w-lg
      mt-5;
  }

  .bg-image {
    @apply
      -z-1
      absolute
      right-5
      -bottom-28
      opacity-0;

    width: 35rem;

    @screen sm {
      opacity: 0.025;
    }
  }

  .stats {
    @apply
      my-10
      px-4
      py-2
      rounded-lg
      bg-primary-lgray
      relative
      overflow-hidden
      z-10;

    &::after {
      content: 'STATISTICHE';

      @apply
        absolute
        -right-1
        -top-1.5
        text-white
        uppercase
        font-bold
        tracking-wider
        text-3xl
        -z-1;
    }

    ul {
      li {
        @apply
          text-sm
          text-primary-black;

        .changing {
          @apply
            basic-transition
            transform;

          animation: change 2s 5 ease-in-out;
        }
      }

      @keyframes change {
        0% {
          @apply
            text-primary-black;
        }
        100% {
          @apply
            text-primary-orange;
        }
      }
    }
  }

  .download-form {
    @apply
      border-t
    border-primary-gray
      pt-5
      mt-10;

    .download {
      @apply
        text-primary-orange
        ml-1
        mb-4
        mr-2;

      span {
        @apply
          basic-transition
          inline-block
          h-5
          w-5
          leading-5
          border
          border-primary-orange;
      }

      &:hover {
        @apply
          text-primary-dorange;

        span {
          @apply
            bg-primary-orange
            text-white;
        }
      }

      & + input {
        @apply
          w-auto
          pb-0;

        & + span {
          @apply
            w-auto
            inline-block
            rounded-none
            pt-2;
        }
      }
    }

  }

  .table-wrapper {
    @apply
      overflow-auto;

    .card {
      @apply
        rounded-xl;
    }
  }

  .active-campaigns,
  .past-campaigns {
    @apply
      bg-primary-lgray
      pt-10
      pb-20
      mt-14;

    .error {
      @apply
        text-primary-black
        opacity-30
        text-center;
    }

    ul {
      @apply
        flex
        flex-wrap;

      li {
        @apply
          w-full;

        @screen sm {
          @apply
            w-1/2
            px-2;
        }
        
        @screen lg {
          @apply
            w-1/3;
        }
      }
    }
  }

  .past-campaigns {
    @apply
      mt-0
      bg-transparent;

    article {
      @apply
        bg-primary-lgray
        shadow-md
        opacity-75;

      &:hover {
        @apply
          shadow-lg
          opacity-100;
      }
    }
  }
}